import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Translation from "../../i18n";
import SenwellLogo from "../../Assets/Images/Home/SenwellLogo.png";
import { Modal } from "react-bootstrap";

function Header() {
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const { t, i18n } = Translation();
  const [enquiryNumber, setEnquiryNumber] = useState(9156060999);
  const [isClicked, setIsClicked] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const handleNavItemClick = () => {
    setNavbarOpen(false);
  };

  const changeLanguage = (e) => {
    const selectedLang = e.target.value;
    i18n.changeLanguage(selectedLang);
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setNavbarOpen(false);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const onChangeNumber = (e) => {
    setEnquiryNumber(e.target.value);
  };

  return (
    <>
      <section>
        <div id="header-parent-div">
          <li className="child-li">
            <i
              className="fa-solid fa-phone fa-xl"
              onClick={() => {
                setIsClicked(true);
              }}
            ></i>
          </li>
          <li className="child-li">
            <a
              className="text-dark"
              target="_blank"
              href="https://www.linkedin.com/company/senwell-exports-private-limited/"
            >
              <i
                className="fa-brands fa-linkedin fa-xl mx-1"
                title="LinkedIn"
              ></i>
            </a>
          </li>
          <li className="child-li">
            <a
              className="text-dark"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100068983571168"
            >
              <i
                className="fa-brands fa-facebook fa-xl mx-1"
                title="Facebook"
              ></i>
            </a>
          </li>
          <li className="child-li">
            <a
              className="text-dark"
              target="_blank"
              href="https://www.instagram.com/senwellgroup/"
            >
              <i
                className="fa-brands fa-instagram fa-xl mx-1"
                title="Instagram"
              ></i>
            </a>
          </li>
          <li className="child-li">
            <a
              className="text-dark"
              target="_blank"
              href="https://www.youtube.com/@senwellgroup"
            >
              <i
                className="fa-brands fa-youtube fa-xl mx-1"
                title="Twitter"
              ></i>
            </a>
          </li>
          <li className="child-li">
            <select onChange={changeLanguage} id="language">
              <option value="en">English</option>
              <option value="es">Spanish</option>
              <option value="fr">French</option>
            </select>
          </li>
        </div>
      </section>

      <section className="first-navbar">
        <div id="top-header" className="header-top-bar align-items-center">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="topbar-left">
                <div className="Header-Certified header-item">
                  <span>{t("companyCertification")}</span>
                </div>
                <div className="header-email header-item">
                  <i className="fa-regular fa-envelope"></i>
                  <a href="mailto:mkt@senwellexports.com">{t("contactEmail")}</a>
                </div>
              </div>

              <div className="top-right header-top-bar">
                <div
                  className="header-phone header-item "
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <i className="fa-solid fa-phone"></i>
                  <span>{t("EnquireNow")}:</span>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    <select
                      onChange={onChangeNumber}
                      id="language"
                      style={{ height: "21px" }}
                    >
                      <option value="9156060999">For Sales</option>
                      <option value="9307450053">For HR</option>
                    </select>
                    <a href={`tel:+91 ${enquiryNumber}`}>{enquiryNumber}</a>
                  </div>
                </div>
                <div className="header-phone header-item ">
                  <div className="lang mx-2">
                    <select onChange={changeLanguage} id="language">
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="fr">French</option>
                      <option value="gr">German</option>

                    </select>
                  </div>
                </div>
                <ul>
                  <li className="topbar-social">
                    <div className="social-icon ">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/senwell-exports-private-limited/"
                      >
                        <i className="fa-brands fa-linkedin-in"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100068983571168"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/senwellgroup/"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@senwellgroup"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar navbar-expand-xl p-3  shadow">
      <div className="container-fluid">
      <div className="logo d-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexGrow: 1 }}>
        <img
          src={SenwellLogo}
          className="img-thumbnail rotating-logo"
          alt="Senwell Exports Logo"
          style={{ border: 'none', width: '90px', height: '90px', background: 'none', borderRadius: '50%' }}
        />
        <Link
          to="/"
          className="navbar-brand navbar-brand-custom mx-1"
          style={{
            color: '#2d67aa',
            fontWeight: '600',
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            display: 'inline-block',
            marginLeft: '10px',
          }}
        >
          Senwell <span style={{ color: '#3AC4F2', fontWeight: '600' }}>Exports</span>
        </Link>
          </div>
          <div>
          <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button></div>
         
          <div
            className={`collapse navbar-collapse ${isNavbarOpen ? "" : " d-none"}`} 
            id="navbarSupportedContent"
            
          >
            <ul className="navbar-nav mx-5 mx-auto mb-2  mt-2">
              <li className="nav-item mx-1">
                <Link to="/" onClick={handleNavItemClick} className="nav-link fw-medium" aria-current="page">
                  {t("Home")}
                </Link>
              </li>
              <li className="nav-item mx-1 dropdown">
                <Link className="nav-link fw-medium">{t("TheCompany")}</Link>
                <ul
                  className="dropdown-menu shadow"
                >
                  <li className="nav-item1 dropdown">
                    <Link to="/about" onClick={handleNavItemClick}>{t("AboutUs")}</Link>
                  </li>
                  <li className="nav-item1 dropdown electricDropdown">
                    <Link to="/csr" onClick={handleNavItemClick}>{t("CSR")}</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-1 dropdown">
                <Link className="nav-link fw-medium" role="button">
                  {t("Product")}
                </Link>
                <ul
                  className="dropdown-menu shadow  rounded aerospace"
                >
                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/aerospace-industries"
                      className="nav-link fw-medium"
                      role="button" onClick={handleNavItemClick}
                    >
                      {t("Aerospace")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/oil-gas"
                      className="nav-link fw-medium"
                      role="button" onClick={handleNavItemClick}
                    >
                      {t("OilAndGas")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/energy"
                      className="nav-link fw-medium"
                      role="button" onClick={handleNavItemClick}
                    >
                      {t("Energy")}
                    </Link>
                    <ul className="dropdown-menu-second shadow rounded">
                      <li className="nav-item">
                        <Link
                          to="/terminal-blocks"
                          className="nav-link fw-medium"
                        >
                          {t("TerminalBlock")}
                        </Link>
                        <Link
                          to="/fastener-fixing"
                          className="nav-link fw-medium"
                        >
                          {t("FastenersAndFixings")}
                        </Link>
                        <Link
                          to="/electrical-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("ElectricalAccessories")}
                        </Link>

                        <Link
                          to="/brass-electrical-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("BrassElectricalAccessories")}
                        </Link>
                        <Link to="/CableTies" className="nav-link fw-medium">
                          {t("CableGlands")}
                        </Link>

                        <Link
                          to="/coppper-brass-earthing-accessories"
                          className="nav-link fw-medium"
                        >
                          {t("EarthingAccessories")}
                        </Link>

                        <Link to="/CableTies2" className="nav-link fw-medium">
                          {t("CableTies")}
                        </Link>

                        <Link
                          to="/casting-product"
                          className="nav-link fw-medium"
                        >
                          {t("CastingProducts")}
                        </Link>

                        <Link
                          to="/metal-enclosure-component"
                          className="nav-link fw-medium"
                        >
                          {t("MetalEnclosure")}
                        </Link>

                        <Link
                          to="/turned-miled-forged"
                          className="nav-link fw-medium"
                        >
                          {t("TurnedMilledForgedParts")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/quality"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Quality")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/service"
                  className="nav-link fw-medium"
                  aria-current="page"
                >
                  {t("Service")}
                </Link>
              </li>
              <li className="nav-item mx-1 dropdown">
                <Link className="nav-link fw-medium" role="button">
                  {t("Blogs & News")}
                </Link>
                <ul
                  className="dropdown-menu shadow  rounded aerospace"
                >
                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/news"
                      className="nav-link fw-medium"
                      role="button"
                      aria-current="page" onClick={handleNavItemClick}
                    >
                      {t("news")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/blog"
                      className="nav-link fw-medium"
                      role="button"
                      aria-current="page" onClick={handleNavItemClick}
                    >
                      {t("blogs")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown electricDropdown">
                    <Link
                      to="/events"
                      className="nav-link fw-medium"
                      role="button"
                      aria-current="page" onClick={handleNavItemClick}
                    >
                      {t("events")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/career"
                  className="nav-link fw-medium"
                  aria-current="page" onClick={handleNavItemClick}
                >
                  {t("Career")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/infrastructure"
                  className="nav-link fw-medium"
                  aria-current="page" onClick={handleNavItemClick}
                >
                  {t("Infrastructure")}
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  to="/contact"
                  className="nav-link fw-medium"
                  aria-current="page" onClick={handleNavItemClick}
                >
                  {t("Contact_us")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Modal show={isClicked} onHide={() => setIsClicked(false)} size="lg">
        <>
          <Modal.Header closeButton>
            <Modal.Title>Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-xl">
              <div className="row">
                <div className="col-md-12 d-flex gap-3 align-items-center justify-content-center">
                  <strong className="fs-3"> For Sales</strong>
                  <a
                    href="tel:+91 9156060999"
                    className="text-decoration-none text-dark  fs-4 align"
                  >
                    9156060999
                  </a>
                </div>
                <div className="col-md-12 d-flex  gap-3 align-items-center justify-content-center">
                  <strong className="fs-3">For HR</strong>
                  <a
                    href="tel:+91 9307450053"
                    className="text-decoration-none text-dark fs-4"
                  >
                    9307450053
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import infradata from "./Infra.json";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const VmmMachine = () => {
  const { id } = useParams();
  const [machine, setMachine] = useState(null); 

 
  useEffect(() => {
    const selectedMachine = infradata.infradata.find((item) => item.id === id);
    setMachine(selectedMachine); 
  }, [id]); 

  if (!machine) {
    return <div>Loading...</div>; 
  }

  // MetaData for Open Graph and Twitter Cards
  const metaData = {
    title: machine.title,
    description: `Learn more about the ${machine.title} at Senwell Exports. Key features and specifications available.`,
    imageUrl: `https://senwellexports.com${machine.imageUrl}`,
    url: `https://senwellexports.com/infra/${machine.id}`,
  };


  return (
    <>
      {/* Open Graph & Twitter Card Metadata */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={metaData.url} />

        {/* Open Graph Meta Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta property="og:image" content={metaData.imageUrl} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:type" content="image/png" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:url" content={metaData.url} />
        <meta name="twitter:image" content={metaData.imageUrl} />
        <meta name="twitter:creator" content="@SenwellExports" />
      </Helmet>

      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={machine.imageUrl} // Access the image of the selected machine
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8">
            <table
              className="table table-striped table-bordered table-responsive-md"
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="4"
                    style={{
                      textAlign: "center",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    {machine.title} {/* Display the title of the selected machine */}
                  </th>
                </tr>
              </thead>

              <tbody>
                {machine.tableData.map((key, index) => (
                  <tr key={index}>
                    <th>{key.fKey}</th>
                    <td>{key.fValue}</td>
                    {key.sKey && (
                      <>
                        <th>{key.sKey}</th>
                        <td>{key.sVAlue}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VmmMachine;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EventsData } from "./event";
import "./event.css";
import HireD from "../../Assets/Images/blog/HireD.jpg";
import bookAppoint from "../../Assets/Images/blog/bookAppoint.jpg";
import { Link } from "react-router-dom";

const EventDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    const url = window.location.href.split("/");
    const updatedUrl = url[url.length - 1];
    const isValidURL = EventsData.some((data) => data.index === updatedUrl);
    if (location.state == null && updatedUrl !== "" && !isValidURL) {
      navigate("/");
    }
    const Event = EventsData.find(
      (data) =>
        data.index == (location.state ? location.state.data : updatedUrl)
    );
    if (Event) setData(Event);
  }, []);

  const goToContact = () => {
    navigate("/contact");
  };
  const goToScheduleCall = () => {
    navigate("/schedulecall");
  };


  return (
    <section className="event-description-container">
      {data && (
        <>
          <div className="news-container">
            <h1 style={{ fontWeight: "600", padding: "20px 0px 5px 0px" }}>
              {data.title}
            </h1>
            <img src={data.image} alt={data.title} className="bannerimg" />
            <p
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              Image Source - {data.imageSource}
            </p>
            <h3
              style={{
                textAlign: "start",
                fontSize: "1.75rem",
                fontWeight: "600",
              }}
            >
              {data.subtitle}
            </h3>
            <p
              dangerouslySetInnerHTML={{ __html: data.Description }}
              className="EventText"
              style={{ backgroundColor: "white" }}
            />{" "}
          </div>

          
          <img
            src={bookAppoint}
            alt="HireDeveloper"
            className="HireDev cursor-pointer"
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              marginTop: "20px",
            }}
            onClick={goToScheduleCall}
          />
        </>
      )}
    </section>
  );
};

export default EventDescription;

import React from "react";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import './ScheduleCall.css'
import bannerImage from "../../Assets/Images/ContactUs/bannerImage.jpg"
import mobileBanner from "../../Assets/Images/ContactUs/mobileBanner.jpg"
import useIsMobile from "../hooks/useIsMobile";

const CustomScheduleCall = () => {
  const isMobile = useIsMobile(768)

    return (
      <div className="custom-wrapper">
        <div className="custom-container">
          {/* Image Section */}
          <div className="custom-image-section">
           {isMobile ?
             <img src={mobileBanner} alt="Banner" width="100%" height="219px" />
            : <img src={bannerImage} alt="Banner" className="custom-image" />
            
            }
          </div>
  
          {/* Calendly Section */}
          <div className="custom-calendly-section">
            <InlineWidget
              url="https://calendly.com/senwellexports-marketing/30min"
              styles={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default CustomScheduleCall;
  
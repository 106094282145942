import React, { useState } from "react";
import news1 from "../../Assets/Images/news/newsBannersss.webp";
import "./News.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NewsData from "./newsData.json";

function News() {
  const NewSectionJsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "@id": "https://senwellexports.com/news",
    url: "https://senwellexports.com/news",
    headline: "Latest News from Senwell Exports Private Limited",
    description:
      "Read the latest updates and news from Senwell Exports Pvt Ltd including innovations, insights, and industry developments.",
    mainEntityOfPage: "https://senwellexports.com/news",
    publisher: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        width: 82,
        height: 82,
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  const [newsD, setNewsD] = useState(NewsData.NewsData || []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Latest News from Senwell Exports Private Limited </title>
        <meta
          name="description"
          content="Latest updates and news from Senwell Exports Pvt Ltd. Stay informed on our recent developments and achievements."
        />
        <link rel="canonical" href="https://senwellexports.com/news" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Senwell Exports Pvt Ltd News" />
        <meta
          property="og:description"
          content="Read the latest updates and news from Senwell Exports Pvt Ltd including innovations, insights, and industry developments."
        />
        <meta property="og:url" content="https://senwellexports.com/news" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/newsBannersss.70394c08057bf5256676.webp"
        />
        <meta property="og:image:width" content="1519" />
        <meta property="og:image:height" content="399" />
        <meta property="og:image:type" content="image/webp" />
        <script type="application/ld+json">
          {JSON.stringify(NewSectionJsonLd)}
        </script>
      </Helmet>
      <img src={news1} class="img-fluid w-100" alt="Responsive image" />
      <section className="news-card-section">
        <div className="container">
          <div className="row">
            {newsD.map((data, index) => (
              <div className="col-md-4 my-3">
                <Link
                  className="blogLinks"
                  to={`/news/${data.route}`}
                >
                  <div className="Exportcard blogbox">
                    <div className="px-4 pt-4">
                      <img
                        src={data.img}
                        alt={data.subtitle}
                        className="blog-ig"
                      />
                    </div>
                    <div className="card-body mnhgt-175 px-4">
                      <p className="card-text">{data.date}</p>
                      <h4 className="card-title text-dark">{data.title}</h4>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default News;

import React from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ModalComponent.css";

import { FaRocket } from "react-icons/fa";

const ModalComponent = ({
  show,
  handleClose,
  imageUrl,
  redirectUrl,
  buttonText,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="custom-modal"
      aria-labelledby="appointment-modal"
      size="lg"
    >
      <button type="button" className="close-button" onClick={handleClose}>
        <FaTimes />
      </button>

      <div className="modal-body-content">
        <img src={imageUrl} alt="Banner" className="img-fluid event-banner" />

        <div className="action-container">
          <div className="rocket-icon-outside">
            <FaRocket />
          </div>
          <a
            href={redirectUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="book-button"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import AboutHome from "../../Assets/Images/AboutUs/aboutHome.jpg";
import CountLessBanenr from "../../Assets/Images/AboutUs/core products-2.jpg";
import countless from "../../Assets/Images/AboutUs/Countless.png";
import crouselImg1 from "../../Assets/Images/AboutUs/about1.png";
import crouselImg2 from "../../Assets/Images/AboutUs/about2.png";
import crouselImg3 from "../../Assets/Images/AboutUs/about3.png";
import mission from "../../Assets/Images/AboutUs/mission.png";
import vision from "../../Assets/Images/AboutUs/vision.png";
import team from "../../Assets/Images/AboutUs/team.png";
import quality from "../../Assets/Images/AboutUs/quality.png";
import whyImg from "../../Assets/Images/AboutUs/choose.png";
import Titanium from "../../Assets/Images/AboutUs/Titanium-2.jpg";
import Inconel from "../../Assets/Images/AboutUs/Inconel-2.jpg";
import Stainless from "../../Assets/Images/AboutUs/Stainless Steel.webp";
import Aluminium from "../../Assets/Images/AboutUs/Aluminium.webp";
import brass from "../../Assets/Images/AboutUs/Brass.webp";
import Copper from "../../Assets/Images/AboutUs/Copper.webp";
import infra1 from "../../Assets/Images/AboutUs/infra1.jpg";
import infra2 from "../../Assets/Images/Infra/NewMachine1.jpg";
import infra3 from "../../Assets/Images/Infra/NewMachine3.jpg";
import infra4 from "../../Assets/Images/Infra/NewMachine5.jpg";
import Translation from "../../i18n";
import "./About.css";
import NewsLetter from "../NewsLetter";
import MyBook from "../BookFlip";
import emailjs from "emailjs-com";
import { addDoc, collection } from "firebase/firestore/lite";
import db from "../../firebaseConfig";
import { Tab, Table } from "react-bootstrap";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const About = () => {
  const { t } = Translation();
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailStatusMessage, setEmailStatusMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const validateForm = (field, value) => {
    const newErrors = { ...errors };
    if (field || value) {
      if (field === "name" && !value) {
        newErrors.name = "Name is required.";
      } else {
        delete newErrors.name;
      }
      if (field === "email") {
        if (!value) {
          newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          newErrors.email = "Invalid email format.";
        } else {
          delete newErrors.email;
        }
      }
      if (field === "companyName" && !value) {
        newErrors.companyName = "Company name is required.";
      } else {
        delete newErrors.companyName;
      }
      if (field === "message" && !value) {
        newErrors.message = "Message is required.";
      } else {
        delete newErrors.message;
      }
    } else {
      if (!formData.name) newErrors.name = "Name is required.";
      if (!formData.email) newErrors.email = "Email is required.";
      else if (!/\S+@\S+\.\S+/.test(formData.email))
        newErrors.email = "Invalid email format.";
      if (!formData.companyName)
        newErrors.companyName = "Company name is required.";
      if (!formData.message) newErrors.message = "Message is required.";
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    }
    setErrors(newErrors);
  };

  const aboutJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/about",
    url: "https://senwellexports.com/about",
    name: "About Us | Senwell Exports Pvt Ltd",
    description:
      "Senwell Exports delivers high-quality precision machining components globally for Aerospace, Defence, Energy, and Oil & Gas sectors and on-time delivery.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Export Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };

  const materials = [
    { image: Titanium, text: "Titanium" },
    { image: Inconel, text: "Inconel" },
    { image: Stainless, text: "Stainless-Steel" },
    { image: Aluminium, text: "Aluminium" },
    { image: brass, text: "Brass" },
    { image: Copper, text: "Copper" },
  ];

  const goToMaterialDescription = (type) => {
    const isSpecialMaterial = type === "Brass" || type === "Copper";
    const path = isSpecialMaterial ? "#" : "/aerospace-industries";
    navigate(path);
  };

  const pages = [
    {
      number: 1,
      title: "Page 1",
      image: "/images/newsletter2024/Newsletter-01.jpg",
      text: "This is page 1.",
    },
    {
      number: 2,
      title: "Page 2",
      image: "/images/newsletter2024/Newsletter-02.jpg",
      text: "This is page 2.",
    },
    {
      number: 3,
      title: "Page 3",
      image: "/images/newsletter2024/Newsletter-03.jpg",
      text: "This is page 3.",
    },
    {
      number: 4,
      title: "Page 4",
      image: "/images/newsletter2024/Newsletter-04.jpg",
      text: "This is page 4.",
    },
    {
      number: 5,
      title: "Page 5",
      image: "/images/newsletter2024/Newsletter-05.jpg",
      text: "This is page 5.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-06.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-07.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-08.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-09.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-10.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-11.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-12.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-13.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-14.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-15.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-16.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-17.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-18.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-19.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-20.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-21.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-22.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-23.jpg",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2024/Newsletter-24.jpg",
      text: "This is page 6.",
    },
  ];

  const page2023 = [
    {
      number: 1,
      title: "Page 1",
      image: "/images/newsletter2023/Newsletter-01.png",
      text: "This is page 1.",
    },
    {
      number: 2,
      title: "Page 2",
      image: "/images/newsletter2023/Newsletter-02.png",
      text: "This is page 2.",
    },
    {
      number: 3,
      title: "Page 3",
      image: "/images/newsletter2023/Newsletter-03.png",
      text: "This is page 3.",
    },
    {
      number: 4,
      title: "Page 4",
      image: "/images/newsletter2023/Newsletter-04.png",
      text: "This is page 4.",
    },
    {
      number: 5,
      title: "Page 5",
      image: "/images/newsletter2023/Newsletter-05.png",
      text: "This is page 5.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-06.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-07.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-08.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-09.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-10.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-11.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-12.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-13.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-14.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-15.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-16.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-17.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-18.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-19.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-20.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-21.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-22.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-23.png",
      text: "This is page 6.",
    },
    {
      number: 6,
      title: "Page 6",
      image: "/images/newsletter2023/Newsletter-24.png",
      text: "This is page 6.",
    },
  ];

  const tableJSON = [
    {
      sr_no: "1",
      machine_name: "CNC-Turning",
      specification: "Dia 200 X Length 300 mm",
      make: "Tsugani",
    },
    {
      sr_no: "2",
      machine_name: "CNC-Turning",
      specification: "Dia 200 X Length 300 mm",
      make: "Tsugani",
    },
    {
      sr_no: "3",
      machine_name: "CNC-Milling 3-Axis",
      specification: "750X400X500 mm",
      make: "Hass",
    },
    {
      sr_no: "4",
      machine_name: "CNC-Milling 4-Axis",
      specification: "750X400X500 mm",
      make: "Hass",
    },
    {
      sr_no: "5",
      machine_name: "SPM",
      specification: "Drilling/Tapping upto 300 mm Lenght",
      make: "Delta",
    },
    {
      sr_no: "6",
      machine_name: "SPM",
      specification: "Drilling/Tapping upto 300 mm Lenght",
      make: "Delta",
    },
    {
      sr_no: "7",
      machine_name: "SPM",
      specification: "Drilling/Tapping upto 300 mm Lenght",
      make: "Delta",
    },
    {
      sr_no: "8",
      machine_name: "SPM",
      specification: "Drilling/Tapping upto 300 mm Lenght",
      make: "Delta",
    },
    {
      sr_no: "9",
      machine_name: "SPM",
      specification: "Drilling/Tapping upto 300 mm Lenght",
      make: "Delta",
    },
    {
      sr_no: "10",
      machine_name: "AUTO BANDSAW",
      specification: "Dia 250MM",
      make: "PCT",
    },
    {
      sr_no: "11",
      machine_name: "CNC-Turning",
      specification: "Dia 250 X Lenght 500 mm",
      make: "LMW",
    },
    {
      sr_no: "12",
      machine_name: "CNC-Turning",
      specification: "Dia 250 X Lenght 500 mm",
      make: "LMW",
    },
    {
      sr_no: "13",
      machine_name: "CNC-Turning",
      specification: "Dia 250 X Lenght 500 mm",
      make: "LMW",
    },
    {
      sr_no: "14",
      machine_name: "CNC-Turning-Mill",
      specification: "Dia 250 X Lenght 300 mm XY+/-45mm",
      make: "LMW",
    },
    {
      sr_no: "15",
      machine_name: "CNC-Milling 3-Axis",
      specification: "800X500X500 mm",
      make: "LMW",
    },
    {
      sr_no: "16",
      machine_name: "CNC-Turning",
      specification: "Dia 200 X Lenght 550 mm",
      make: "ACE",
    },
  ];

  const handlePageFlip = (currentPage) => {
    //console.log('Current page:', currentPage);
  };

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    if (validateForm()) {
      e.preventDefault();
      setIsLoading(true);
      try {
        const now = new Date();
        const currentDate = now.toLocaleDateString();
        const currentTime = now.toLocaleTimeString();
        const enhancedData = {
          ...formData,
          date: currentDate,
          time: currentTime,
        };
        const contactsRef = collection(db, "newsletter_contacts");
        const emailSucess = await sendEmail(formData.email, formData.name);
        if (emailSucess || emailStatusMessage)
          await addDoc(contactsRef, enhancedData);
        setShowSuccess(true);
        setTimeout(() => {
          setShowModal(false);
          setShowSuccess(false);
        }, 1000);
      } catch (error) {
        setEmailStatusMessage(false);
        console.error("Error sending message:", error);
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setShowModal(false);
          setShowSuccess(false);
        }, 1000);
        setFormData({
          name: "",
          email: "",
          companyName: "",
          message: "",
        });
      }
    }
  };

  const sendEmail = async (email, name) => {
    const message = `Thank you for subscribing to our newsletter!.`;
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: "Senwell",
          company_name: "Senwell Exports Private Limited",
          to_email: email,
          to_name: name,
          message: message,
        },
        USER_ID
      )
      .then(() => {
        console.log("Email sent successfully");
        setEmailStatusMessage(true);
        return true;
      })
      .catch((error) => {
        setEmailStatusMessage(false);
        console.error("Error sending email:", error);
        return false;
      });
  };
  const openSubscribeModel = () => {
    setShowModal(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Precision Machining Components Manufacturer</title>
        <meta
          name="description"
          content="Senwell Exports delivers high-quality precision machining components globally for Aerospace, Defence, Energy, and Oil & Gas sectors and on-time delivery."
        />
        <link rel="canonical" href="https://senwellexports.com/about" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="About Us | Countless Products One Purpose"
        />
        <meta
          property="og:description"
          content="Senwell Exports Private Limited. is a dynamic and vibrant enterprise specializing in the production and exportation of top-tier non-ferrous metal components and electrical accessories."
        />
        <meta property="og:url" content="https://senwellexports.com/about" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/aboutHome.9c922303951a572db844.png"
        />
        <meta property="og:image:width" content="293" />
        <meta property="og:image:height" content="396" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">
          {JSON.stringify(aboutJsonLd)}
        </script>
      </Helmet>
      <section className="about-section-home">
        <div className="about-section-img">
          <img src={AboutHome} className="img-fluid" alt="AboutHome" />
          <div className="text-overlay">
            <h2>{t("AboutUs")}</h2>
          </div>
        </div>
      </section>
      <div className=" container  newsLetterWrapper">
        <MyBook
          pages={page2023}
          width={300}
          height={423}
          onFlip={handlePageFlip}
          title={"2023 Newsletter"}
          openSubscribeModel={openSubscribeModel}
        />
        <MyBook
          pages={pages}
          width={300}
          height={423}
          onFlip={handlePageFlip}
          title={"2024 Newsletter"}
          openSubscribeModel={openSubscribeModel}
        />
      </div>
      <NewsLetter
        handleSubmit={handleSubmit}
        showSuccess={showSuccess}
        emailStatusMessage={emailStatusMessage}
        isLoading={isLoading}
        validateForm={validateForm}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        showModal={showModal}
        setShowModal={setShowModal}
        openSubscribeModel={openSubscribeModel}
      />

      <section className="about-section-second">
        <div className="container shadow p-3 rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-md-4 col-sm-8">
              <img
                src={CountLessBanenr}
                className="img-fluid mt-5"
                alt="img"
                style={{ width: "100%", marginTop: "0px" }}
              />
            </div>
            <div className="col-xl-8 col-md-8 ">
              <div className="about-section-second-heading ">
                <h2> {t("Countless")} </h2>
                <h2 className="mx-2 purpose">{t("onePurpose")}</h2>
              </div>
              <div className="about-section-second-para mt-4">
                <p>{t("counttext")}</p>
                <p>{t("counttext1")}</p>
                <p>{t("counttext3")}</p>
              </div>
              <div className="about-section-second-icons-section  mt-4">
                <div className="row">
                  <img src={countless} alt="countless" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-third-opportunity mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="heading">
                <h2>{t("big")}</h2>
                <h2 className="mx-2 opportunity">{t("business")}</h2>
              </div>
              <div className="heading-para ">
                <p>{t("growthtext")}</p>
                <p>{t("growthtext1")}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="lightbox">
                <div className="row lastimg" style={{ marginTop: "54px" }}>
                  <div className="col-lg-6">
                    <img
                      src={crouselImg1}
                      alt="Table Full of Spices"
                      className="w-100 h-50 mb-2 mb-md-4 shadow-1-strong "
                    />
                    <img
                      src={crouselImg2}
                      alt="Coconut with Strawberries"
                      className="w-100 h-50  shadow-1-strong "
                    />
                  </div>
                  <div className="col-lg-6 last-img last-mg">
                    <img
                      src={crouselImg3}
                      alt="Dark Roast Iced Coffee"
                      className="w-100   shadow-1-strong "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 ">
              <div className="about-section-mission-img">
                <img src={mission} alt="mission" />
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("mision")}</div>
                  <p>{t("missiontext")}</p>
                  <ol>
                    <li>Safety & Reliability</li>
                    <li>Sustainable Innovation</li>
                    <li> Exceed Expectations</li>
                    <li> Excellence & Integrity</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col1">
              <div className="about-section-mission-img">
                <img src={vision} className="img-thumbnail" alt="vision" />
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("vision")}</div>
                  <p>{t("visiontext")}</p>
                  <ol>
                    <li>Customer Satisfaction</li>
                    <li>Quality Commitment</li>
                    <li>Sustainable Solutions</li>
                    <li> Supplier Collaboration</li>
                    <li>Community Engagement</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col">
              <div className="about-section-mission-img">
                <img src={team} className="img-thumbnail" alt="team"></img>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="custom-button mb-2">{t("Management")}</div>
                  <p>{t("Management-text")}</p>
                  <ol>
                    <li>Innovation</li>
                    <li>Process Ownership</li>
                    <li>Guaranteed Savings</li>
                    <li>Performance Efficiency</li>
                    <li>Design Optimization</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mission-col">
              <div className="about-section-mission-img">
                <img
                  src={quality}
                  className="img-thumbnail"
                  alt="quality"
                ></img>
              </div>
              <div className="card">
                <div className="card-body ">
                  <div className="custom-button mb-2">{t("Quality")}</div>
                  <p>{t("quality-text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whychoose-sections">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 why-leftSide">
              <h2>
                {t("why")} <span>{t("Choose")}</span>
              </h2>
              <p>{t("Choose-text")}</p>
              <div className="row section4-row2">
                <div className="col-lg-4  my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("focus")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Competative")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Precision")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Exceptional")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Continuou")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Superior")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Packaging")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3  ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Deliver")} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 section2-col-2 why-img">
              <img src={whyImg} alt="img" className="mt-5" />
            </div>
          </div>
        </div>
      </section>

      <section className="material-section">
        <div className="container">
          <div className="section-title">
            <h2>{t("materials")}</h2>
            <div className="row">
              {materials.map((data, index) => (
                <div
                  key={index}
                  className="team-block col-lg-4 col-md-6 col-sm-12 my-3"
                  onClick={() => {
                    goToMaterialDescription(data.text);
                  }}
                >
                  <div>
                    <div className="m-image">
                      <img src={data.image} alt="Titanium" />
                    </div>
                  </div>
                  <div className="lower-content-titanium">
                    <h4>{t(`${data.text}`)}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="our-infrastructure-section mt-3">
        <div className="container infrastructure-section">
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "50px" }}>
              <div className="infra-heading">
                <h1>{t("our")}</h1>
                <h1 className="infra">{t("Infrastructure")}</h1>
              </div>
              <div className="infra-para mt-3">
                <p>{t("infratext1")}</p>
              </div>

              <div className="infra-readMore">
                <Link to="/infrastructure" className="inner-infra">
                  {t("ReadMore")}
                </Link>
              </div>
            </div>

            <div className="col-md-6 ">
              <h4 className="table-heder-name">MACHINE CAPACITY</h4>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th className="th">Sr.NO.</th>
                    <th className="th">MACHINE NAME</th>
                    <th className="th">SPECIFICATION</th>
                    <th className="th make">MAKE</th>
                  </tr>
                </thead>
                <tbody className="">
                  {tableJSON.map((data) => {
                    return (
                      <tr key={data.sr_no}>
                        <td id="serial-no" style={{background:"#bfccd0"}}>{data.sr_no}</td>
                        <td className="td" style={{background:"#bfccd0"}}  >{data.machine_name}</td>
                        <td className="td" style={{backgroundColor:"#dcdfe0"}}>{data.specification}</td>
                        <td className="td" style={{background:"#dcdfe0"}}>{data.make}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  );
};

export default About;

import React from "react";
import "./News1.css";
import { Helmet } from "react-helmet";

function News1() {
  const NewsArticleJsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "@id":
      "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    url: "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    headline: "GE Aerospace Advances the Development Collaboration with NASA",
    description:
      "GE Aerospace and NASA are advancing hybrid electric engines, driving innovations for a more sustainable future in aviation.",
    datePublished: "2024-06-24",
    mainEntityOfPage:
      "https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA",
    publisher: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        width: 82,
        height: 82,
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
    image: {
      "@type": "ImageObject",
      url: "https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG",
      width: 366,
      height: 200,
      caption: "GE Aerospace Advances the Development Collaboration with NASA",
    },
    keywords:
      "GE Aerospace, NASA, Aerospace Collaboration, Development News, Senwell Exports",
    articleSection: ["Aerospace News", "Industry Updates"],
    inLanguage: "en-US",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          GE Aerospace Advances the Development Collaboration with NASA
        </title>
        <meta
          name="description"
          content="GE Aerospace and NASA are advancing hybrid electric engines, driving innovations for a more sustainable future in aviation."
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="GE Aerospace advances the development of hybrid electric engines in collaboration with NASA."
        />
        <meta
          property="og:description"
          content=" GE Aerospace (GE.N) is taking significant strides in developing a hybrid electric engine aimed at powering next-generation narrow-body jets by the mid-2030s. This innovative engine, which integrates electric motor/generators within a high-bypass turbofan, could revolutionize aviation by reducing carbon emissions and fuel consumption."
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG"
        />
        <meta property="og:image:width" content="366" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:type" content="image/jpg" />
        <script type="application/ld+json">
          {JSON.stringify(NewsArticleJsonLd)}
        </script>
      </Helmet>
    </>
  );
}

export default News1;

import React, { useState } from "react";
import HTMLFlipBook from "react-pageflip"; // Install with: npm install react-pageflip
import "./MyBook.css"; // Add custom styles if needed

const Page = React.forwardRef(
  ({ isBlurred, number, title, image, openSubscribeModel, children }, ref) => {
    return (
      <div className={`demoPage`} ref={ref}>
      <div className="image-wrapper">
  <img
    src={image}
    alt={`Page ${number}`}
    style={{ width: "100%", height: "auto" }}
    className={`${isBlurred ? "blur" :""}`}
  />
  {(isBlurred || number > 3) && (
    <div className="subscribe-message">
      <p>If you want to see the full content, please subscribe!</p>
      <button
        className="btn btn-primary"
        onClick={() => openSubscribeModel()}
      >
        Subscribe
      </button>
    </div>
  )}
</div>
      </div>
    );
  }
);

function MyBook({
  pages,
  width = 300,
  height = 500,
  onFlip,
  title,
  openSubscribeModel,
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const handleFlip = (e) => {
    const { data } = e;
    setCurrentPage(data);
    if (onFlip) onFlip(data);
  };

  return (
    <div>
      <h1 className="TitleText">{title}</h1>
      <div
       className="eachBook"
      >
        <HTMLFlipBook
          width={width}
          height={height}
          onFlip={handleFlip}
          showCover={true}
        >
          {pages.map((page, index) => (
            <Page
              key={index}
              number={page.number}
              title={page.title}
              image={page.image}
              isBlurred={index >= 2 && currentPage >= 2}
              openSubscribeModel={openSubscribeModel}
            >
              {page.text}
            </Page>
          ))}
        </HTMLFlipBook>
      </div>
    </div>
  );
}

export default MyBook;

import React from "react";
import "./Blog.css";
import CNCPrecision from "../../Assets/Images/blog/CNCPrecision.jpg";
import highquality from "../../Assets/Images/blog/outsideBanner.webp";
import exportsreach from "../../Assets/Images/blog/Senwell-exports.jpg";
import AerospaceMaterialsOutside from "../../Assets/Images/blog/AerospaceMaterialsOutside.jpg";
import keyfactore from "../../Assets/Images/blog/Key Factors.jpg";
import fromvision from "../../Assets/Images/blog/From vision.jpg";
import mastering from "../../Assets/Images/blog/Mastering.jpg";
import elevate from "../../Assets/Images/blog/Elevate.jpg";
import blog6 from "../../Assets/Images/blog/Machining1.png";
import market from "../../Assets/Images/blog/Marketing.png";
import blog1 from "../../Assets/Images/blog/Cultural.jpg";
import blog2 from "../../Assets/Images/blog/Comprehensive.webp";
import blog3 from "../../Assets/Images/blog/Overcoming.webp";
import blog4 from "../../Assets/Images/blog/four.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  const blogJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/blog",
    url: "https://senwellexports.com/blog",
    name: "Senwell Exports Pvt Ltd Blogs on Precision Machining",
    description:
      "Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports Pvt Ltd Blogs on Precision Machining</title>
        <meta
          name="description"
          content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more."
        />
        <link rel="canonical" href="https://senwellexports.com/blog" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Blog | Senwell Exports Pvt Ltd" />
        <meta
          property="og:description"
          content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more."
        />
        <meta property="og:url" content="https://senwellexports.com/blog" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/blogBg.b210bb540f2cecd75e60.png"
        />
        <meta property="og:image:width" content="1683" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">{JSON.stringify(blogJsonLd)}</script>
      </Helmet>

      <div className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sub-header">
                <h1>Blog</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-2 my-4">
        <div className="container">
          <div className="row">

          <div className="col-md-4 my-3">
          <Link
            className="blogLinks"
            to="/blog/The-Role-of-Specialized-Aerospace-Materials-and-Their-Applications"
          >
            <div className="Exportcard blogbox">
              <div className="px-4 pt-4">
                <img src={AerospaceMaterialsOutside} alt="image" className="blog-ig" />
              </div>
              <div className="card-body mnhgt-175 px-4">
                <p className="card-text">23 January, 2025</p>
                <h4 className="card-title text-dark">
                The Role of Specialized Aerospace Materials and Their Applications
                </h4>
              </div>
            </div>
          </Link>
        </div>

            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/CNC-Precision-Machining-A-Comprehensive-Guide"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={CNCPrecision} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">16 January, 2025</p>
                    <h4 className="card-title text-dark">
                      What is CNC Precision Machining? A Comprehensive Guide
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/The-Role-of-Precision-Manufacturing-in-Delivering-High-Quality-Products"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={highquality} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">10 August, 2024</p>
                    <h4 className="card-title text-dark">
                      The Role of Precision Manufacturing in Delivering
                      High-Quality Products...
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001:2015-Certification"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={exportsreach} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">11 July, 2024</p>
                    <h4 className="card-title text-dark">
                      Senwell Exports Reaches New Heights with AS9100D and ISO
                      9001:2015...
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/Key-Factors-to-Consider-When-Choosing-an-Aerospace-Manufacturing-Company"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img
                      src={keyfactore}
                      alt="keyfactore"
                      className="blog-ig"
                    />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">28 June , 2024</p>
                    <h4 className="card-title text-dark">
                      Key Factors to Consider When Choosing an Aerospace...
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/From-vision-to-reality">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={fromvision} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">14 May, 2024</p>
                    <h4 className="card-title text-dark">
                      From Vision to Reality: The Role of New Technologies in
                      Senwell Exports' Aerospace Innovation.
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/mastering-precision">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={mastering} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">19 April, 2024</p>
                    <h4 className="card-title text-dark">
                      Mastering Precision: 6 Essential Tips for Selecting
                      Reliable Precision Component Suppliers
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/elevateprecision">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={elevate} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">11 March, 2024</p>
                    <h4 className="card-title text-dark">
                      Elevate Precision Component Manufacturing with Senwell
                      Exports: Unveiling Our Superior Capabilities
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/precision-machining">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog6} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">14 Feb, 2024</p>
                    <h4 className="card-title text-dark">
                      Precision Machining Benefits in Aerospace Industry
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/Market">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={market} alt="market" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">12 January, 2024</p>
                    <h4 className="card-title text-dark">
                      Market Strategies For Global Success for Exports’ Industry
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/UnlockingSuccess">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog4} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">6 January, 2023</p>
                    <h4 className="card-title text-dark">
                      Importance of Supply Chain Management in Manufacturing .
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/OvercomingManufacturing">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog3} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">12 July, 2023</p>
                    <h4 className="card-title text-dark">
                      Overcoming Manufacturing and Export Challenges
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/CompresiveGuide">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog2} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">20 October, 2023</p>
                    <h4 className="card-title text-dark">
                      A Comprehensive guide to International Trade Regulations
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/cultural-sensitivity">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog1} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">15 Dec, 2023</p>
                    <h4 className="card-title text-dark">
                      Cultural Sensitivity in International Trade
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;

import React, { useEffect, useState } from "react";
import "./Infrastructure.css";
import Infra from "../../Assets/Images/Infra/InfraHome.jpg";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import infradata from "./Infra.json";

const Infrastructure = () => {
  const navigate = useNavigate();
  const { t } = Translation();

  const infrastructureJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/infrastructure",
    url: "https://senwellexports.com/infrastructure",
    name: "Infrastructure at Senwell Exports Pvt Ltd - Our Facilities",
    description:
      "Explore Senwell Exports Pvt Ltd’s advanced infrastructure, including top facilities, equipment, and technologies that drive our manufacturing excellence.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };

  const [infrastructureData] = useState(infradata.infradata || []);

  const getdata = (splitData, title) => {
    return (
      <li>
        <span>{title}</span> - 
        {splitData.map((splitItem, splitKey) => (
          <React.Fragment key={splitKey}>
            {splitKey < splitData.length - 1 ? (
              <span>
                {splitItem} <br />{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ) : (
              <span>{splitItem} &nbsp;&nbsp;&nbsp;&nbsp;</span>
            )}
          </React.Fragment>
        ))}
      </li>
    );
  };
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Infrastructure at Senwell Exports Pvt Ltd - Our Facilities
        </title>
        <meta
          name="description"
          content="Explore Senwell Exports Pvt Ltd’s advanced infrastructure, including top facilities, equipment, and technologies that drive our manufacturing excellence."
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/infrastructure"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Machining Set Up" />
        <meta
          property="og:description"
          content="We utilize state-of-the-art automated machinery, sourced from USA, Japan, and India, to achieve unparalleled precision and efficiency. This advanced technology allows us to seamlessly meet our customers' critical requirements on a large scale."
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/infrastructure"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/InfrastructureBanner.ed77aaf0759b7292f42f.png"
        />
        <meta property="og:image:width" content="1519" />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(infrastructureJsonLd)}
        </script>
      </Helmet>
      <div>
        <div className="Infrastructure-section-home">
          <div className="infrastructure-img">
            <img src={Infra} alt="img" />
            <div className="text-Infra">
              <p>{t("Infrastructure")}</p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <article className="content span12">
              <div className="row">
                <div className="span12 sepage-header">
                  <h1>{t("Machining-Set")}</h1>
                  <p align="justify">{t("Machining Set Text")}</p>
                </div>
                <p>&nbsp;</p>
              </div>
            </article>
          </div>
        </div>

        <section className="Unit-Sections">
          <div className="container px-4">
            <h1>{t("Innovative")}</h1>
            <p style={{marginLeft:"10%",marginBottom:"20px"}}>{t("Innovative-text")}</p>
            <div className="container">
              <div className="row g-4">
                {infrastructureData.map((data, key) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 export-img" key={key}>
                    <img
                      src={data.imageUrl}
                      alt="Newmachine8"
                      className="Machine-image"
                      onClick={() => {
                        if (data.tableData) {
                          navigate(`/infra/${data.id}`);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <p className="exe-text" style={{marginBottom:"-30px"}}>{data.title}</p>
                    {data?.hover && (
                      <div className="hover-info-coordinate">
                        <ol className="custom-dot-list">
                          {data.hover[0].make && 
                            getdata(data.hover[0].make.split(","),"Make")}
                          
                          {data.hover[0].model && 
                            getdata(data.hover[0].model.split(","),"Model")}

                          {data.hover[0].probStyle && 
                              getdata(data.hover[0].probStyle.split(","),"Prob Style")}
  
                          {data.hover[0].size &&
                            getdata(data.hover[0].size.split(","),"Size")}
                            
                        </ol>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Infrastructure;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import newsData from "./newsData.json"; 

function SpecificNews() {
  const param = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const newsItem = newsData.NewsData.find(
      (item) => item.route === param.newsName
    );
    setData(newsItem); 
  }, [param.newsName]);

  if (!data) return <div>Loading...</div>; 

  return (
    <div className="news-container">
      <h1 style={{ fontWeight:"600", padding:"20px 0px 5px 0px"}}>{data.title}</h1>
      <img src={data.bannerImg} alt={data.title} className="bannerimg" />
      <p style={{display:"flex", width:"100%", justifyContent:"flex-end"}}>Image Source - {data.imageSource}</p>
      <h3 style={{textAlign:"start", fontSize:"1.70rem", fontWeight:"600"}}>{data.subtitle}</h3>
      <p dangerouslySetInnerHTML={{ __html: data.description }} style={{fontSize:"1.1rem"}} />
    </div>
  );
}

export default SpecificNews;

import React from "react";
import { Container, Button, Modal, Form } from "react-bootstrap";
import BookAnimation from "./BookAnimation";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const NewsLetter = ({
  handleSubmit,
  showSuccess,
  emailStatusMessage,
  isLoading,
  validateForm,
  formData,
  setFormData,
  errors,
  showModal,
  setShowModal,
  openSubscribeModel,
}) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateForm(name, value);
  };

  const floating = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  `;

  const blinking = keyframes`
  50% {
    opacity: 0.5;
  }
  `;

  const AnimatedButton = styled(Button)`
    background-color: #007bff;
    border: none;
    border-radius: 20px;
    color: white;
    margin: 10px 20px;
    padding: 10px 20px;
    font-size: 21px;
    min-width: 30%;
    font-weight: bold;
    animation: ${floating} 3s ease-in-out infinite,
      ${blinking} 1.5s linear infinite;
    &:hover {
      background-color: #0056b3;
    }
  `;

  return (
    <div className="py-4 material-section text-white">
      <Container className="text-center">
        <h4>Stay Updated with Our Newsletters</h4>
        <AnimatedButton variant="primary" onClick={()=>openSubscribeModel()}>
          Subscribe
        </AnimatedButton>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        {showSuccess ? (
          <div className="p-4 d-flex align-items-center justify-content-center gap-3">
            <h3 className="p-1">
              {emailStatusMessage
                ? "Email Sent Successfully"
                : "Please try again after some time"}{" "}
            </h3>
            {emailStatusMessage ? (
              <IoCheckmarkDoneSharp
                style={{ color: "green", fontSize: "50px" }}
              />
            ) : (
              <RxCross2 style={{ color: "red", fontSize: "50px" }} />
            )}
          </div>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Fill Details for Newsletter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <BookAnimation />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Form
                      onSubmit={(e) => {
                        handleSubmit(e, formData);
                      }}
                    >
                      <Form.Group className="mb-1">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-1">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          value={formData.companyName}
                          onChange={handleChange}
                          isInvalid={!!errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          isInvalid={!!errors.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button variant="primary" type="submit" className="w-100">
                        {isLoading ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default NewsLetter;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {EventsData} from "./event";
import "./event.css"

const Events = () => {
  const blogJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/blog",
    url: "https://senwellexports.com/blog",
    name: "Senwell Exports Pvt Ltd Blogs on Precision Machining",
    description:
      "Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports Pvt Ltd Events on Precision Machining</title>
        <meta
          name="description"
          content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more."
        />
        <link rel="canonical" href="https://senwellexports.com/blog" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Events | Senwell Exports Pvt Ltd" />
        <meta
          property="og:description"
          content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more."
        />
        <meta property="og:url" content="https://senwellexports.com/blog" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/blogBg.b210bb540f2cecd75e60.png"
        />
        <meta property="og:image:width" content="1683" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">{JSON.stringify(blogJsonLd)}</script>
      </Helmet>

      <div className="event-section">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sub-header">
                  <h1 className="text-light">Events</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-2 my-4">
        <div className="container">
          <h2>Upcoming Events</h2>
          <div className="row">
            {EventsData &&
                EventsData?.map((data, index) => (
                <div className="col-md-4 my-3" key={index}>
                  <div
                    className="blogLinks"
                    onClick={() => {
                      navigate(`/events/${data.index}`, {
                        state: { data: data.index },
                      });
                    }}
                  >
                    <div className="Exportcard blogbox">
                      <div className="px-4 pt-4">
                        <img src={data.image} alt="image" className="blog-ig" />
                      </div>
                      <div className="card-body mnhgt-175 px-4">
                        <p className="card-text">{data.Date}</p>
                        <h4 className="card-title text-dark">
                          {data.subtitle}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
